import React,{useEffect, useState} from 'react'
import { useData } from '../DataProvider/DataProvider';
import '../../scripts/animate_mob';
import axios from 'axios';
export default function Contactus({location}) {
  const { sharedData } = useData();
  const [contactForm,setContactForm]=useState({name:'',email:'',message:'',type:'',code:''});
  const [type,setType]=useState(sharedData?sharedData:'Select');
  const [isSent,setIsSent]=useState(false);
  const [isValidEmail,setIsValidEmail]=useState(false);
  const [isValidCode,setIsValidCode]=useState(false);
  useEffect(()=>{
    setContactForm((prev)=>({...prev,type:type}))
  },[type])

  const submitContactForm = (e)=>{
    e.preventDefault();
    console.log(contactForm,type);
    axios.post('https://www.api.quantasteller.com/contactuscontent/sendenquiry',contactForm)
    .then((response)=>{
      console.log(response);
      return axios.post('https://www.api.quantasteller.com/contactuscontent/customeremail',contactForm)
      setContactForm({name:'',email:'',message:'',type:''});
    })
    .catch((err)=>{
      console.log(err);
    })
    .catch((err)=>{
      console.log(err);
    });
    window.alert('Your Message Has Been Sent Successfully!!');
    setIsSent(true);
  };

  const sendVerification = (e)=>{
    e.preventDefault();
    axios.post('https://www.api.quantasteller.com/contactuscontent/verifyemail',{
      name:contactForm.name,
      email:contactForm.email
    })
    .then((response)=>{
      alert('Verification code has been sent to your email.');
    })
    .catch((err)=>{
      console.log(err);
    });
  }
  const verifyCode = (code)=>{
    axios.post('https://www.api.quantasteller.com/contactuscontent/verifycode',{
      code:code,
    })
    .then((response)=>{
      alert('Email is verified.');
      setIsValidCode(true);
    })
    .catch((err)=>{
      console.log(err);
    });
  }
  const changeEnquire = (e)=>{
    setType(e.target.value);
    setContactForm((prev)=>({...prev,type:type}))
  };
  const validateEmail = () => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Matches email format
    const isValid = pattern.test(contactForm.email);
    setIsValidEmail(isValid);
  };
  const validateCode = (e)=>{
    e.preventDefault();
    if(e.target.value.length===6){
    verifyCode(e.target.value);
    }
  }
  
  return (
    <>
   <div className="overlay">
    <div className="sub-overlay">
      <div className="form-info">
        <div className="sub-info number">
          <div className='flex-n'><img src="https://res.cloudinary.com/dn07sxmaf/image/upload/v1699892624/samples/QuantastellerWebsite/teenyicons_phone-solid_xxygqs.png" alt="" />  <h2 className="form-title">Contact</h2></div>
          <div className='mg-lft-1'><a href="" className="text-link">+91 9373017141</a>
          <a href="" className="text-link">+91 7350707575</a>
          </div>
        </div>
        <div className="sub-info mail">
         <div className="flex-n"><img src="https://res.cloudinary.com/dn07sxmaf/image/upload/v1699892623/samples/QuantastellerWebsite/fluent_mail-28-filled_n4zcha.png" alt="" />      <h2 className="form-title">Mail</h2></div> 
          <div className="mg-lft-1"><a href="" className="text-link">quantasteller.tech@gmail.com</a></div>
        </div>
      </div>
      <div>
      <form  className='form over-x'>
        <h1 className="title">Get in Touch</h1>
         <input className='input-field' placeholder='Name' type="text" name="name" onChange={(e)=>{setContactForm((prev)=>({...prev,name:e.target.value}))}}/>
        <br />
        <div className='form-email-button'>
       <input className='input-field reveal-from-left-mob' style={{"width":"210px"}} placeholder='Email' type="email" name="email" onChange={(e)=>{setContactForm((prev)=>({...prev,email:e.target.value})); validateEmail();}}/>
       <button className='bg-main reveal-from-right-mob' style={{'padding':'10px 10px'}} disabled={!isValidEmail}  onClick={sendVerification}>OTP</button>
       </div>
       {isValidEmail ? <span style={{ color: 'green' }}>Valid email address</span> : null}
       <br />
       
        <input className='input-field' placeholder='Enter OTP' type="text" name="code" onChange={(e)=>{setContactForm((prev)=>({...prev,code:e.target.value})); validateCode(e);}}/>
        <br />
        <select placeholder='Enquiry' className='input-field reveal-from-right-mob' name="enquireform" value={type} onChange={changeEnquire}>
          <option value="">Select</option>
          <option value="web dev">Website Development</option>
          <option value="desktop app dev">Desktop App Development</option>
          <option value="mobile app dev">Mobile App Development</option>
          <option value="product dev">Product Development</option>
          <option value="design and ui">Design And UI</option>
        </select>
     
        <br />
        <input placeholder='Message' className='input-field reveal-from-left-mob' type="text" name="message" onChange={(e)=>{setContactForm((prev)=>({...prev,message:e.target.value}))}}/>
        <br />
        <button className='bg-main reveal-from-right-mob' disabled={!isValidCode}  onClick={submitContactForm}>Submit</button>
      </form>
    </div>
    </div>
   </div>
    
    </>
  )
}
