import React from 'react';
import Slider from '../Slider/slider'
import Carousel3D from '../Carousel/carousel';
import '../../styles/main.css'
import '../../scripts/animate'
export default function Home() {
  return (<>
    <Slider/>
    <Carousel3D className='reveal'/>

    {/* <div className='customers-panel'>
        <div className='customer-card'>

        </div>
        <div className='customer-card'>
          
        </div>
    </div> */}


  <h1 className='title mg-dwn reveal over-x'>Technology Stack</h1>
  <div className='tech-stack-mega over-x'>
    <div className='curvyImg'>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#67B9DB" fill-opacity="1" d="M0,64L80,96C160,128,320,192,480,186.7C640,181,800,107,960,106.7C1120,107,1280,181,1360,218.7L1440,256L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path></svg>
    </div>
    
      <div className='tech-stack-container'>
        <div className='tech-stack-sub'>
            <div className='tech-stack tech1 reveal-from-left'><img src="https://res.cloudinary.com/dn07sxmaf/image/upload/v1699886513/samples/QuantastellerWebsite/tech1_bw0ff8.png" alt="" /></div>
            <div className='tech-stack tech1 reveal'><img src="https://res.cloudinary.com/dn07sxmaf/image/upload/v1699886512/samples/QuantastellerWebsite/tech2_agkgfa.png" alt="" /></div>
            <div className='tech-stack tech1 reveal-from-right'><img src="https://res.cloudinary.com/dn07sxmaf/image/upload/v1699886512/samples/QuantastellerWebsite/tech3_mupiqs.png" alt="" /></div>
        </div>
      </div>
    
           
  </div>
    </>
  )
}
