import React from 'react'
import '../../styles/main.css'

export default function Products() {
  return (
    <div className='big-text'>
      <h1 className="big-text-prod">Products will be listed here shortly ...</h1>
    </div>
  )
}
