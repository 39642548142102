import React from 'react'
import { DataProvider } from '../DataProvider/DataProvider'
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className='footer-mega'>
      <div className="footer-logo ">
        <img className='logo' src="https://res.cloudinary.com/dn07sxmaf/image/upload/v1699891732/samples/QuantastellerWebsite/Frame_4_2_q6ewhb.png" alt="" />
      </div>
      <div className="footer-title title">QuantaStellar</div>
      <div className="navlinks text">
        <Link className='text-link' to='/'>Home</Link>
        <Link className='text-link' to='/Aboutus'>AboutUs</Link>
        <Link className='text-link' to='/Products'>Products</Link>
        <Link className='text-link' to='/Services'>Services</Link>
        <Link className='text-link' to='/Contactus'>ContactUs</Link>
      </div>
      <div className="footer-socials navlinks">
        <a href="https://twitter.com/WeAreQSteller" target='_blank' className="text-link">
          <img src="https://res.cloudinary.com/dn07sxmaf/image/upload/v1699892625/samples/QuantastellerWebsite/ri_twitter-x-line_oiy6e4.png" alt="" />
        </a>

        <a href="https://www.linkedin.com/company/quantasteller/" target='_blank' className="text-link">
          <img src="https://res.cloudinary.com/dn07sxmaf/image/upload/v1699892627/samples/QuantastellerWebsite/mdi_linkedin_ker5qx.png" alt="" />
        </a>

        <a href="mailto:quantasteller.tech@gmail.com" className="text-link">
          <img src="https://res.cloudinary.com/dn07sxmaf/image/upload/v1699892623/samples/QuantastellerWebsite/fluent_mail-28-filled_n4zcha.png" alt="" />
        </a>

        <a href=" https://instagram.com/quantasteller?utm_source=qr&igshid=MzNlNGNkZWQ4Mg==" target='_blank' className="text-link">
          <img src="https://res.cloudinary.com/dn07sxmaf/image/upload/v1699892628/samples/QuantastellerWebsite/ri_instagram-fill_xo8z7s.png" alt="" />
        </a>

        <Link to={'/contactus'} className="text-link">
          <img src="https://res.cloudinary.com/dn07sxmaf/image/upload/v1699892624/samples/QuantastellerWebsite/teenyicons_phone-solid_xxygqs.png" alt="" />
        </Link>

      </div>
    </div>
  )
}
