import React from 'react'
import '../../scripts/animate'
import sameer from '../Images/sameer.jpg'
import harsh from '../Images/harsh.jpg'
import harish from '../Images/harish.jpg'
import dheeraj from '../Images/dheeraj.png'
import amar from '../Images/amar.jpg'

export default function Aboutus() {

  return (
    <>
      <div className="outer-container-abt">
        <h1 className="abt-title title">Who we are</h1>
        <div className="abs-half-overlay-img-container">
          <img className='half-overlay-img' src="https://res.cloudinary.com/dn07sxmaf/image/upload/v1700730174/samples/QuantastellerWebsite/Triangle_Design_s2dz6b.png" alt="" />
        </div>
        <div className="mega-grid-2-abt">
          <div>
          <div className="text content">
          &nbsp; &nbsp; &nbsp; &nbsp; At Quantasteller, we are pioneers in crafting tomorrow's digital landscape. We specialize in the development of versatile software solutions, encompassing web applications, desktop applications, and cutting-edge AI/ML models. Our team's expertise lies in seamlessly integrating technology and innovation, redefining industries and user experiences. We are driven by a passion for revolutionizing the way businesses and individuals interact with technology. Our commitment to excellence
            and relentless pursuit of groundbreaking solutions empowers us to create transformative products that shape a smarter, interconnected world.
            </div>
            <br />
            <div className="text content">
            &nbsp; &nbsp; &nbsp; &nbsp; We believe in the power of collaboration, forging partnerships that foster creativity and drive progress. Our mission is to empower businesses and individuals alike with accessible, user-centric applications that transcend boundaries and propel industries forward. With a foundation built on innovation, ethics, and customer-centricity, we aim to set new
              standards in software development, ensuring reliability, excellence, and utmost satisfaction for our users.
            </div>
            <br />
            <div className="text content">
            &nbsp; &nbsp; &nbsp; &nbsp;  Quantasteller stands at the forefront of technological evolution, dedicated to delivering impactful solutions that make a difference. Join us on this transformative journey, where possibilities are limitless, and the future is ours to shape.
            </div>
          </div>
          <div className="blank-container">

          </div>
        </div>
        </div>
      <div className="mega-grid-abt over-x">
    
        <h1 className="title">Our Team</h1>

        <div className="card-profile reveal-from-left">

          <div >
            <img className="photo-profile reveal-from-left" src={sameer} alt="" />
          </div>
          <div className="profile-content">
            <h2 className="name-profile title reveal-from-right">Sameer Jahagirdar</h2>
            <div className="logo-container">
               <a className='reveal-from-left' target="_blank" href="https://www.linkedin.com/in/sameer-jahagirdar-973253164/"> <img src="https://res.cloudinary.com/dn07sxmaf/image/upload/v1700732959/samples/QuantastellerWebsite/bi_linkedin_ouhwxb.png" alt="" />  </a>
              <a className='reveal-from-right' href='mailto:sameer.quantasteller@gmail.com'> <img src="https://res.cloudinary.com/dn07sxmaf/image/upload/v1700732959/samples/QuantastellerWebsite/fluent_mail-24-filled_hjhjts.png" alt="" /> </a>
            </div>
            <div className="small-content-abt text reveal-from-left">
            Meet the mind behind the code! As a seasoned full stack developer. Translating countless ideas into seamless digital experiences. With a knack for blending design and functionality
            </div>
          </div>

        </div>



        <div className="card-profile reveal-from-right">

        <div >
            <img className="photo-profile reveal-from-left" src={harsh} alt="" />
          </div>
<div className="profile-content">
  <h2 className="name-profile title reveal-from-right">Harsh Ekbote</h2>
  <div className="logo-container">
     <a className='reveal-from-left' target="_blank"  href="https://www.linkedin.com/in/harsh-ekbote-90528b212"> <img src="https://res.cloudinary.com/dn07sxmaf/image/upload/v1700732959/samples/QuantastellerWebsite/bi_linkedin_ouhwxb.png" alt="" />  </a>
    <a className='reveal-from-right' href='mailto:harsh.quantasteller@gmail.com'> <img src="https://res.cloudinary.com/dn07sxmaf/image/upload/v1700732959/samples/QuantastellerWebsite/fluent_mail-24-filled_hjhjts.png" alt="" /> </a>
  </div>
  <div className="small-content-abt text reveal-from-left">
  A visionary leader at Quantasteller, drives design, AI/ML integration, and management. His expertise in design aesthetics and AI/ML orchestrates seamless workflows, crafting impactful, user-centric solutions. Committed to excellence, he shapes our company's success and innovation by pushing boundaries and fostering collaborative creativity.
  </div>
</div>

</div>



<div className="card-profile reveal-from-left">

<div >
            <img className="photo-profile reveal-from-left" src={amar} alt="" />
          </div>
<div className="profile-content">
  <h2 className="name-profile title reveal-from-right">Amar Gaikwad</h2>
  <div className="logo-container">
     <a className='reveal-from-left' target="_blank" href=" https://www.linkedin.com/in/amar-gaikwad-913126230"> <img src="https://res.cloudinary.com/dn07sxmaf/image/upload/v1700732959/samples/QuantastellerWebsite/bi_linkedin_ouhwxb.png" alt="" />  </a>
    <a className='reveal-from-right' href='mailto:amargaikwad.quantasteller@gmail.com'> <img src="https://res.cloudinary.com/dn07sxmaf/image/upload/v1700732959/samples/QuantastellerWebsite/fluent_mail-24-filled_hjhjts.png" alt="" /> </a>
  </div>
  <div className="small-content-abt text reveal-from-left">
  Passionate Frontend, Java, and Spring Boot Developer, dedicated to crafting seamless user experiences and robust backend solutions. Proficient in translating design into code and building scalable applications with a keen eye for detail. Excited about innovation and constantly striving to stay ahead in the ever-evolving tech landscape. Let's bring ideas to life through code!
  </div>
</div>

</div>



<div className="card-profile reveal-from-right">

<div >
            <img className="photo-profile reveal-from-left" src={dheeraj} alt="" />
          </div>
<div className="profile-content">
  <h2 className="name-profile title reveal-from-right">Dheeraj Jadhav</h2>
  <div className="logo-container">
     <a className='reveal-from-left' target="_blank" href="https://www.linkedin.com/in/dheeraj-jadhav-979a541a5/"> <img src="https://res.cloudinary.com/dn07sxmaf/image/upload/v1700732959/samples/QuantastellerWebsite/bi_linkedin_ouhwxb.png" alt="" />  </a>
    <a className='reveal-from-right' href='mailto:dheeraj.quantasteller@gmail.com'> <img src="https://res.cloudinary.com/dn07sxmaf/image/upload/v1700732959/samples/QuantastellerWebsite/fluent_mail-24-filled_hjhjts.png" alt="" /> </a>
  </div>
  <div className="small-content-abt text reveal-from-left">
  A dynamic force at Quantasteller, excel as a full-stack developer and UI/UX designer. With a keen eye for design aesthetics and a flair for SDLC he craft seamless workflows, delivering impactful, user-centric solutions. Committed to excellence, he shape Quantasteller's success by pushing boundaries and fostering collaborative creativity.
  </div>
</div>

</div>


<div className="card-profile reveal-from-left">

<div >
            <img className="photo-profile reveal-from-left" src={harish} alt="" />
          </div>
<div className="profile-content">
  <h2 className="name-profile title reveal-from-right">Harish Bapat</h2>
  <div className="logo-container">
     <a className='reveal-from-left' target="_blank" href="https://www.linkedin.com/in/harish-bapat"> <img src="https://res.cloudinary.com/dn07sxmaf/image/upload/v1700732959/samples/QuantastellerWebsite/bi_linkedin_ouhwxb.png" alt="" />  </a>
    <a className='reveal-from-right' href='mailto:harish.quantasteller@gmail.com'> <img src="https://res.cloudinary.com/dn07sxmaf/image/upload/v1700732959/samples/QuantastellerWebsite/fluent_mail-24-filled_hjhjts.png" alt="" /> </a>
  </div>
  <div className="small-content-abt text reveal-from-left">
  Meet our exceptional ML Engineer, a visionary expert with a proven track record in crafting cutting-edge image and pattern recognition models. With a wealth of experience in the realm of large language models and chatbots, they seamlessly blend innovation and expertise to bring unparalleled solutions to the forefront. Whether decoding complex visual data or orchestrating dynamic conversational AI experiences, our ML Engineer is at the forefront of the technological frontier, shaping the future
  </div>
</div>

</div>


      </div>
    </>
  )
}
