import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useData } from '../DataProvider/DataProvider';
import '../../styles/main.css'
import '../../scripts/animate'
import '../../scripts/animate_mob'
export default function Services() {
  const [type, setType] = useState('');
  const { setSharedDataValue } = useData();
  const navigate = useNavigate();

  const handleEnquire = (val) => {
    setType(val);
    setSharedDataValue(val);
    navigate('/contactus')

  }
  return (
    <>
      <div className='title-centered'> <h1 className='title services' data-title="Our Services"></h1></div>
      <div className='cards over-x'>

        <div className='card-wrap '>
          <div className="card" data-card="Website">

            <img src="https://res.cloudinary.com/dn07sxmaf/image/upload/v1699894805/samples/QuantastellerWebsite/bi_laptop_e036zp.png" alt="" />
            <h1 className='title'>Website Development</h1>
            <p>Crafting innovative and scalable web solutions tailored to meet evolving user needs and industry demands.</p>
            <button className='btn small' onClick={() => handleEnquire('web dev')}>Enquire</button>
          </div>
        </div>
        <div className='card-wrap reveal-from-left-mob'>
          <div className="card" data-card="Desktop App">
            <img src="https://res.cloudinary.com/dn07sxmaf/image/upload/v1699894806/samples/QuantastellerWebsite/la_laptop-code_tthiu8.png" alt="" />
            <h1 className='title'>Desktop App Development</h1>
            <p>Building robust and intuitive desktop applications that enhance productivity and user experience across platforms.</p>
            <button className='btn small' onClick={() => handleEnquire('desktop app dev')}>Enquire</button>
          </div>
        </div>
        <div className='card-wrap reveal-from-right-mob'>
          <div className="card" data-card="Mobile App">
            <img src="https://res.cloudinary.com/dn07sxmaf/image/upload/v1699894804/samples/QuantastellerWebsite/app-development_1_iypkjb.png" alt="" />
            <h1 className='title'>Mobile App Development</h1>
            <p> Designing and developing cutting-edge mobile applications that bring seamless experiences to users on the go.</p>
            <button className='btn small' onClick={() => handleEnquire('mobile app dev')}>Enquire</button>
          </div>
        </div>
        <div className='card-wrap reveal-from-left'>
          <div className="card" data-card="Product">
            <img src="https://res.cloudinary.com/dn07sxmaf/image/upload/v1699894804/samples/QuantastellerWebsite/idea_1_n3gyjs.png" alt="" />
            <h1 className='title'>Product Development</h1>
            <p>Bringing ideas to life with end-to-end product development, from concept and design to deployment and maintenance.</p>
            <button className='btn small' onClick={() => handleEnquire('product dev')}>Enquire</button>
          </div>
        </div>
        <div className='card-wrap reveal-from-right'>
          <div className="card" data-card="Ui/Ux">
            <img src="https://res.cloudinary.com/dn07sxmaf/image/upload/v1699894804/samples/QuantastellerWebsite/vector_1_sasfzf.png" alt="" />
            <h1 className='title'>Design And UI</h1>
            <p>Creating captivating designs and intuitive user interfaces that elevate user engagement and enrich overall experiences.</p>
            <button className='btn small' onClick={() => handleEnquire('design and ui')}>Enquire</button>
          </div>
        </div>
      </div>
    </>
  )
}
