import React, { useState, useRef, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../styles/main.css'
import Slider1 from '../SliderImages/slider1.png'
import Slider2 from '../SliderImages/slider2.png'
import Slider3 from '../SliderImages/slider3.jpg'
import Slider4 from '../SliderImages/slider4.png'
import Slider5 from '../SliderImages/slider5.png'

const Images = [
    Slider1,Slider2,Slider3,Slider4,Slider5
]

const HeroSlider = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const sliderRef = useRef(null);

    const settings = {
        autoplay: true,
        autoplaySpeed: 3000,
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        beforeChange: (oldIndex, newIndex) => {
            setCurrentSlide(newIndex);
        },
    };

    const nextSlide = () => {
        if (sliderRef.current) {
            sliderRef.current.slickNext();
        }
    };

    const prevSlide = () => {
        if (sliderRef.current) {
            sliderRef.current.slickPrev();
        }
    };

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'ArrowRight') {
                nextSlide();
            } else if (e.key === 'ArrowLeft') {
                prevSlide();
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        <div className="hero-slider-container">
            <Slider ref={sliderRef} {...settings}>
                {Images.map((imageUrl, index) => (
                    <div key={index} className="slider-item">
                        <img src={imageUrl} alt={`Slide ${index + 1}`} />
                    </div>
                ))}
            </Slider>
            <div className="slider-controls">
                <img src='https://res.cloudinary.com/dn07sxmaf/image/upload/v1699856231/samples/QuantastellerWebsite/iconamoon_arrow-left-2-bold_cmvn4x.png' onClick={prevSlide}></img>
                <img src='https://res.cloudinary.com/dn07sxmaf/image/upload/v1699856255/samples/QuantastellerWebsite/iconamoon_arrow-left-2-bold_1_wbbktr.png' onClick={nextSlide}></img>
            </div>
        </div>
    );
};

export default HeroSlider;
