import React, { Component } from "react";
import Carousel from "react-spring-3d-carousel";
import '../../scripts/animate'
import { config } from "react-spring";
import "../../styles/main.css"
export default class Carousel3D extends Component {
  state = {
    goToSlide: 0,
    offsetRadius: 2,
    showNavigation: true,
    config: config.gentle
  };

  slides = [
    {
      key: 1,
      content: <div className="cards-carousel"> 
      
      <img src="https://res.cloudinary.com/dn07sxmaf/image/upload/v1699866600/samples/QuantastellerWebsite/fluent_target-arrow-16-filled_qm7c8o.png" alt="2" />
      <div className="text vision">
      Empowering through top-tier web and desktop applications, we innovate AI/ML boundaries for transformative efficiency. Embracing collaboration and continuous learning, we deliver impactful, user-centric software solutions to meet evolving business and individual needs.
      </div>
</div>

    },
    {
      key: 2,
      content:<div className="cards-carousel"> 
      <img src="https://res.cloudinary.com/dn07sxmaf/image/upload/v1699866612/samples/QuantastellerWebsite/solar_eye-scan-bold_t7uaov.png" alt="2" />
      <div className="text vision">
      Reimagining industries via integrated web/desktop tech and AI/ML, fostering a more connected world. Our mission: enhance global efficiency, accessibility through innovative software solutions, and revolutionize user experiences.
      </div>
</div>
    },
    {
      key: 3,
      content: <div className="cards-carousel"> 
      <img src="https://res.cloudinary.com/dn07sxmaf/image/upload/v1699866633/samples/QuantastellerWebsite/vaadin_diamond_udsqlw.png" alt="2" />
      <div className="text vision">
      Driven by innovation, we explore groundbreaking ideas, prioritize excellence, and embrace collaboration, adaptability, and ethical AI. Our commitment: customer-centric, responsible practices, fostering continuous improvement in our culture.
      </div>
</div>
    },
    {
        key: 4,
        content: <div className="cards-carousel"> 
        <img src="https://res.cloudinary.com/dn07sxmaf/image/upload/v1699866600/samples/QuantastellerWebsite/fluent_target-arrow-16-filled_qm7c8o.png" alt="2" />
        <div className="text vision">
        Empowering through top-tier web and desktop applications, we innovate AI/ML boundaries for transformative efficiency. Embracing collaboration and continuous learning, we deliver impactful, user-centric software solutions to meet evolving business and individual needs.
        </div>
  </div>
  
      },
      {
        key: 5,
        content:<div className="cards-carousel"> 
        <img src="https://res.cloudinary.com/dn07sxmaf/image/upload/v1699866612/samples/QuantastellerWebsite/solar_eye-scan-bold_t7uaov.png" alt="2" />
        <div className="text vision">
        Reimagining industries via integrated web/desktop tech and AI/ML, fostering a more connected world. Our mission: enhance global efficiency, accessibility through innovative software solutions, and revolutionize user experiences.
        </div>
  </div>
      },
      {
        key: 6,
        content: <div className="cards-carousel"> 
        <img src="https://res.cloudinary.com/dn07sxmaf/image/upload/v1699866633/samples/QuantastellerWebsite/vaadin_diamond_udsqlw.png" alt="2" />
        <div className="text vision">
        Driven by innovation, we explore groundbreaking ideas, prioritize excellence, and embrace collaboration, adaptability, and ethical AI. Our commitment: customer-centric, responsible practices, fostering continuous improvement in our culture.
        </div>
  </div>
      }
  ].map((slide, index) => {
    return { ...slide, onClick: () => this.setState({ goToSlide: index }) };
  });

  onChangeInput = e => {
    this.setState({
      [e.target.name]: parseInt(e.target.value, 10) || 0
    });
  };
  
  componentDidMount() {
    this.startAutoplay();
  }

  componentWillUnmount() {
    this.stopAutoplay();
  }

  startAutoplay = () => {
    this.autoplayInterval = setInterval(() => {
      if (!this.state.isPaused) {
        this.setState((prevState) => ({
          goToSlide: (prevState.goToSlide + 1) % this.slides.length,
        }));
      }
    }, 8000); // Adjust the autoplay interval as needed (in milliseconds)
  };

  stopAutoplay = () => {
    clearInterval(this.autoplayInterval);
  };

  handleMouseEnter = () => {
    this.setState({ isPaused: true });
  };

  handleMouseLeave = () => {
    this.setState({ isPaused: false });
  };

  handleClick = () => {
    this.setState((prevState) => ({ isPaused: !prevState.isPaused }));
  };

  render() {
    return (<>
<div className="mg-up over-x">
    <h1 className="title vision reveal">Vision and Mission</h1>
      <div style={{ width: "80%", height: "450px", margin: "4rem auto" }}
       onMouseEnter={this.handleMouseEnter}
       onMouseLeave={this.handleMouseLeave}
       onClick={this.handleClick}
       >
        <Carousel
          
          slides={this.slides}
          goToSlide={this.state.goToSlide}
          offsetRadius={this.state.offsetRadius}
         // showNavigation={this.state.showNavigation}
          animationConfig={this.state.config}
        />
        <div
        className = 'reveal-from-left'
          style={{
            margin: "0 auto",
            marginTop: "2rem",
            width: "60%",
            display: "flex",
            justifyContent: "space-around"
          }}
        >
        
          
        </div>
      </div>
      </div>
      </>
    );
  }
}
