import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import Home from './components/Home/Home';
import Aboutus from './components/About Us/Aboutus';
import Products from './components/Products/Products';
import Services from './components/Services/Services';
import Contactus from './components/Contact Us/Contactus';
import Navbar from'./components/Navbar/Navbar';
import Footer from './components/Footer/Footer'
import { DataProvider } from './components/DataProvider/DataProvider';
const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return null;
};

const App = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar/>
        <DataProvider>
        <Routes>
          
          <Route path="/" exact element={<Home />} />
          <Route path="/Aboutus" element={<Aboutus />} />
          <Route path="/Products" element={<Products />} />
          <Route path="/Services" element={<Services />} />
          <Route path="/Contactus" element={<Contactus />} />
          
        </Routes>
        </DataProvider>
        <ScrollToTop />
        <Footer />
      </BrowserRouter>
    </div>
  );
};

export default App;
